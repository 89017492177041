import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalWrapperComponent } from 'src/app/shared/common-components/modal-wrapper/modal-wrapper.component';
import { ModalComponent } from 'src/app/shared/common-components/modal/modal.component';
import {
  MembershipRequestType,
  MembershipStatus,
  TOKEN,
} from 'src/app/shared/constants/app-constants';
import { Company } from 'src/app/shared/models/company';
import { MembershipDto } from 'src/app/shared/models/order-details';
import { Profile } from 'src/app/shared/models/profile';
import { UtilityService } from 'src/app/shared/services/app-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { MembershipService } from 'src/app/shared/services/membership.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { PreloaderService } from 'src/app/shared/services/preloader.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { SalesService } from 'src/app/shared/services/sales.service';
import { BoardAdvisorComponent } from '../board-advisor/board-advisor.component';
import { BoardInformationComponent } from '../board-information/board-information.component';
import { EditPbcComponent } from '../edit-pbc/edit-pbc.component';
import { MemberRosterReviewComponent } from '../member-roster-review/member-roster-review.component';
import { SuperAdminEditMembershipComponent } from '../super-admin-edit-membership/super-admin-edit-membership.component';
import { JSDocComment } from '@angular/compiler';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  companyData!: Company;
  primaryContact: Profile = <Profile>{};
  adminContact!: Profile;
  profiles!: Profile[];
  membership!: MembershipDto | any;
  injector!: Injector;
  boardAdvisor: any;
  unallocatedSeats: number = 0;
  canRenew = false;
  tableData: any;
  isSuperAdmin = false;
  isShowRenew = false;
  companyId!: string;
  isPayNow = false;
  salesData: any;
  netAmount: any;
  allProfiles: any;
  isRenew = 'PAY NOW';
  isAdditional = false;
  isPrimaryAddress = true;
  isAdminAddress = true;
  isCompany = true;
  companyAddress: any;
  companyCity: any;
  pbcAddress: any;
  pbcCity: any;
  adminAddress: any;
  adminCity: any;
  isAdminPhoneHide = false;
  isPrimaryPhoneHide = false;
  salesOrder: any;

  constructor(
    private modal: MatDialog,
    private globalData: GlobalDataProviderService,
    private companyService: CompanyService,
    private membershipService: MembershipService,
    private profileService: ProfileService,
    private inj: Injector,
    private router: Router,
    private authService: AuthService,
    private paymentService: PaymentService,
    private salesService: SalesService,
    private preloaderService: PreloaderService,
    private invoiceService: InvoiceService
  ) { }

  ngOnInit(): void {
    this.globalData.isUpdateBoardInfo = false;
    this.isSuperAdmin = this.authService.isSuperAdminUser()!;
    this.paymentService.multipleInvoice = [];
    this.salesService.salesOrderIds = [];
    this.salesService.isPayLaterAdditionalSeat = false;
    this.salesService.salesOrderId = '';
    this.salesService.isPayLaterAdditionalInvoice = false;
    this.companyData = this.companyService.activeCompany;
    this.profiles = this.globalData.getMemberRosterProfiles();
    this.allProfiles = this.globalData.getMemberRosterProfiles();
    // this.salesService
    //   .cancelSalesOrderCompanyById(this.companyData.id!)
    //   .subscribe((data: any) => {});
    this.membership = this.membershipService.activeMembership!;
    if (
      this.membership.endDate &&
      this.membership.paymentDueDate &&
      new Date(this.membership.endDate!).getTime() ===
      new Date(this.membership.paymentDueDate!).getTime() &&
      this.membership.status?.toLowerCase() === 'pending'
    ) {
      this.isShowRenew = true;
    }
    this.prepareProfileData();
    this.getBoardAdvisor();
    this.boardAdvisor = this.membership.boardAdvisor;
    this.membershipService.getActiveMembershipObs().subscribe((res) => {
      if (res && res.id) {
        this.membership = { ...res };
        this.boardAdvisor = { ...res.boardAdvisor };
      }
    });
    this.globalData.getMemberRosterObservable().subscribe((data) => {
      if (data && data instanceof Array && data.length > 0) {
        this.profiles = data;
        this.prepareProfileData();
      }
    });
    
    this.salesService
    .getOpenSalesOrder(this.companyData.id!)
    .subscribe((data: any) => {
      //if (this.membership.orderId) {
      //  this.salesService
      //    .CreatePaylaterSalesOrder(this.membership.orderId!)
      //    .subscribe({
      //      next: (data: any) => {
      //        this.globalData.setSalesOrder(data.data);
      //        this.salesOrder = data;
      //        this.preloaderService.showLoader();
      //        this.membership.orderId = data.data?.id;
      //        this.validatePaynowButton(data);
      //        this.membershipService
      //          .updateMembership(this.membership!)
      //          .subscribe({
      //            next: (data: any) => {
      //              this.membershipService.setActiveMembership(data.data);
      //              this.membership = data.data;
      //              this.preloaderService.hideLoader();
      //            }
      //          });
      //      },
      //    });
      //}
      this.salesOrder = data;
      this.netAmount = data.data?.netAmount;
      this.globalData.setSalesOrder(data.data);


      if (data.data?.status === 'Close') {
        return;
      }
      if (
        data.data?.isPayNow == false &&
        this.membership.status != 'Completed'
      ) {

        this.isPayNow = true;
      } else if (
        this.membership.status !== 'Completed' &&
        !this.membership.endDate
      ) {

        this.isPayNow = true;
      } else if (data.data?.isPayNow === false) {
        this.isPayNow = true;

      } else {


        this.isPayNow = false;
      }
     //his.validatePaynowButton(data);
    });

    if (this.adminContact?.phone) {
      this.isAdminPhoneHide = true;
    }
    if (this.primaryContact?.phone) {
      this.isPrimaryPhoneHide = true;
    }

    this.formatAddressAndCityForCompany();
    if (this.primaryContact) this.formatAddressAndCityForPBC();
    this.formatAddressAndCityForAdmin();
    // resetting the open invoice toggle
    this.invoiceService.setToggle(false);
    this.membershipService.memberShipTerm = '';
    this.membershipService.membersCount = 0;
  }


  //validatePaynowButton(data: any) {

  //  if (data.data?.status === 'Close') {
  //    return;
  //  }
  //  if (
  //    data.data?.isPayNow == false &&
  //    this.membership.status != 'Completed'
  //  ) {

  //    this.isPayNow = true;
  //  } else if (
  //    this.membership.status !== 'Completed' &&
  //    !this.membership.endDate
  //  ) {

  //    this.isPayNow = true;
  //  } else if (data.data?.isPayNow === false) {
  //    this.isPayNow = true;

  //  } else {


  //    this.isPayNow = false;
  //  }
  //}
  formatAddressAndCityForCompany() {
    this.companyAddress = this.addressLine1Format(this.companyData.address);
    this.companyCity = this.addressLine2Format(this.companyData.address);
  }

  formatAddressAndCityForPBC() {
    this.pbcAddress = this.addressLine1Format(this.primaryContact.address);
    this.pbcCity = this.addressLine2Format(this.primaryContact.address);
  }

  formatAddressAndCityForAdmin() {
    this.adminAddress = this.addressLine1Format(this.adminContact?.address);
    this.adminCity = this.addressLine2Format(this.adminContact?.address);
  }

  addressLine1Format(data: any) {
    let address: any;
    if (data?.line1 && data?.line2) {
      address = data.line1 + '; ' + data.line2;
    } else if (data?.line1) {
      address = data.line1;
    }
    return address;
  }

  addressLine2Format(data: any) {
    const addressLine = [data?.city, data?.state];
    const validAddressLine = addressLine.filter(Boolean);
    let city = validAddressLine.join(', ');
    let address = city + ' ' + data?.zipCode;
    return address;
  }

  prepareProfileData() {
    console.log(this.profiles, 'profiles', this.companyData.id); //checking purpose need to remove

    this.primaryContact = UtilityService.getPrimaryContact(
      this.profiles,
      this.companyData.id!
    );
    this.adminContact = UtilityService.getAdminstrator(
      this.profiles,
      this.companyData.id!
    );
    if (
      this.primaryContact &&
      (!this.adminContact || (this.adminContact && !this.adminContact.id))
    ) {
      this.adminContact = { ...this.primaryContact };
    }
    this.formatAddressAndCityForAdmin();
    this.filterProfilesForRoster();
    this.calculateUnAllocated();
  }

  filterProfilesForRoster() {
    // this.profileArr = [...this.profileArr.filter(p => p.isDeleted === false)];
    if (this.membership) {
      const tempProfileArr: Profile[] = [];
      this.membership.member.forEach((m: any) => {
        const profile = this.profiles.find((p) => p.id === m.id);
        if (profile) {
          profile.member = m;
          tempProfileArr.push(profile);
        }
      });
      this.profiles = tempProfileArr.filter(
        (p) => p.member?.isDeleted === false
      );
    } else {
      this.profiles = [
        ...this.profiles.filter((p) =>
          p.companyInfo?.find(
            (c) => !(c.isAdmin === true && c.isPrimary === false)
          )
        ),
      ];
    }
  }

  private calculateUnAllocated() {
    const activeMembers = this.profiles.filter(
      (p) => p.member?.isActive === true && p.member?.isDeleted === false
    ).length;
    const membershipCount = this.membership ? this.membership.count : 0;
    this.unallocatedSeats = membershipCount - activeMembers;
  }

  getBoardAdvisor() {
    if (this.profiles) {
      this.boardAdvisor = this.profiles.find((p) => p.isBoardAdvisor === true)!;
    }
  }

  editBoard() {
    this.globalData.isUpdateBoardInfo = true;
    this.globalData.isAutoCompleteEnabled = false;
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: BoardInformationComponent,
        title: 'Edit Board Information',
        showCloseIcon: true,
        showDenotes: true,
      },
      panelClass: ['app-dialog', 'account-edit-modal'],
      disableClose: true,
    };
    const modalRef = this.modal.open(ModalWrapperComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      this.globalData.isUpdateBoardInfo = false;
      this.globalData.isAutoCompleteEnabled = true;
      if (item) {
        this.companyData = { ...item };
        this.formatAddressAndCityForCompany();
      }
    });
  }

  editProfile(isPbc: boolean) {
    const data = isPbc ? this.primaryContact : this.adminContact;
    this.injector = Injector.create({
      providers: [
        {
          provide: TOKEN,
          useValue: { contactData: data, isPbc: isPbc },
        },
      ],
      parent: this.inj,
    });

    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: EditPbcComponent,
        title: isPbc ? 'Edit Primary Board Contact' : 'Edit Administrator',
        showCloseIcon: true,
        injector: this.injector,
        showDenotes: true,
      },
      panelClass: ['app-dialog', 'account-edit-modal'],
      disableClose: true,
    };
    const modalRef = this.modal.open(ModalWrapperComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      if (item) {
        this.globalData.setMemberRosterProfile(item);
        if (this.primaryContact) this.formatAddressAndCityForPBC();
      }
    });
  }

  editAdmin() {
    const modalRef = this.modal.open(ModalComponent, {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        buttonOneLabel: 'cancel',
        buttonTwoLabel: 'Add',
        title: 'Edit Administrator',
        bodyComponent: 'edit-administrator',
        showDenotes: true,
      },
      panelClass: 'app-dialog',
      disableClose: true,
    });
    modalRef.afterClosed().subscribe((item) => {
      console.log(item);
      if (item) {
        this.formatAddressAndCityForAdmin();
      }
    });
  }

  private convertToTableData(data: Profile[]) {
    if (data) {
      const companyId = this.companyData.id;
      const mappedData = data.map((profile, index) => ({
        sequence: index + 1,
        member: {
          id: profile.id,
          email: profile.eMail,
          name: profile.firstName + ' ' + profile.lastName,
          pbc: profile.companyInfo?.find((c) => c.id === companyId)?.isPrimary,
        },
        status:
          (profile.member && profile.member.isActive) || !profile.member
            ? true
            : false,
        statusDisplay:
          (profile.member && profile.member.isActive) || !profile.member
            ? 'Active'
            : 'Deactivated',
      }));
      const membership = mappedData.sort(
        (a: any, b: any) => b.status - a.status
      );
      this.tableData = [...membership];
    }
  }

  get isRenewPeriodActive(): boolean {
    return (
      this.membershipService.isRenewPeriodActive &&
      this.membershipService.activeMembership?.status ===
      MembershipStatus.COMPLETE
    );
  }

  doRenew() {
    this.convertToTableData(this.profiles);
    this.injector = Injector.create({
      providers: [{ provide: TOKEN, useValue: this.tableData }],
      parent: this.inj,
    });
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      height: '500px',
      data: {
        component: MemberRosterReviewComponent,
        injector: this.injector,
        showCloseIcon: true,
        title: 'Review roster details',
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };
    const modalRef = this.modal.open(ModalWrapperComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      if (!item) {
        this.preloaderService.hideLoader();
        return;
      }
      if (item && item === 'Roster') {
        this.globalData.membershipUpdateState = MembershipRequestType.RENEW;

        const route = this.isSuperAdmin
          ? '/admin/roster'
          : '/account/manage-roster';

        this.router.navigate([route], { state: { forRenewal: true } });
      }
      if (item && item === 'Payment') {
        this.profileService.rostersForPayment = this.profiles;
        this.globalData.membershipUpdateState = MembershipRequestType.RENEW;
        const route = this.isSuperAdmin
          ? '/admin/order-details'
          : '/order-details';
        this.router.navigate([route]);
      }
    });
  }

  editAdvisor() {
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: BoardAdvisorComponent,
        title: 'NACD Member Engagement Advisor',
        showCloseIcon: true,
        showDenotes: true,
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };
    const modalRef = this.modal.open(ModalWrapperComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      if (item) {
        this.membership = { ...item };
      }
    });
  }

  payNow() {

    console.log("Create Sales Order1");
    this.invoiceService.setToggle(true);
    if (

      this.membership.status?.toLowerCase() === 'pending' &&
      !this.membership.endDate
    ) {
      console.log("Create Sales Order1");
      this.router.navigate(['/admin/order-details'], {
        state: { isPayNow: true },
      });
    } else if (

      (this.membership.status?.toLowerCase() === 'pending' &&
        this.membership.endDate) ||
      !this.salesOrder.data.isPayNow
    ) {
      console.log("Create Sales Order2");
      this.router.navigate(['/admin/order-history'], {
        state: { isOpenInvoice: true },
      });
    }
  }

  getMemberShip(isRestrictLatestMembership = true) {
    this.membershipService
      .getMembershipByCompany(
        this.companyService.activeCompany.id!,
        isRestrictLatestMembership
      )
      .subscribe({
        next: (res) => {
          this.membershipService.activeMembership = res.data;
          this.router.navigate(['/admin']);
        },
      });
  }

  editMembership() {
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: SuperAdminEditMembershipComponent,
        title: 'Edit Membership Details',
        showCloseIcon: true,
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };
    const modalRef = this.modal.open(ModalWrapperComponent, modalConfig);
  }
}
