import { Component, Injector, OnInit } from '@angular/core';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';
import { TOKEN } from 'src/app/shared/constants/app-constants';
import { CommonModule } from '@angular/common';
import { MemberRosterTableComponent } from '../member-roster-table/member-roster-table.component';
import { ButtonComponent } from 'src/app/shared/common-components/button/button.component';

@Component({
    imports: [CommonModule, ButtonComponent, MemberRosterTableComponent],
    selector: 'app-member-roster-review',
    templateUrl: './member-roster-review.component.html',
    styleUrls: ['./member-roster-review.component.scss'],
    standalone: true
})
export class MemberRosterReviewComponent implements OnInit {

    datasource: any;
    nonHistoricMembersInRosterExists = false;
    displayColumnsDefault: string[] = ["sequence", "member", "status"];

    constructor(private modalService: ModalDataService,
        private injector: Injector) {
    }

    ngOnInit(): void {
        const memberRosters = Array.from(this.injector.get(TOKEN));
        if (!!memberRosters?.length) {
            memberRosters.sort((a: any, b: any) => b.status - a.status);
        }
        this.datasource = memberRosters;

        if(memberRosters && memberRosters.length > 0){
            this.nonHistoricMembersInRosterExists = true;
        }
    }

    navigateToRoster() {
        this.modalService.closeModal({ close: true, result: 'Roster' });
    }

    navigateToPayment() {
        this.modalService.closeModal({ close: true, result: 'Payment' });
    }
}