import { Component, Injector, OnInit } from "@angular/core";
import { ModalDataService } from "src/app/shared/services/modal-data.service";
import { TOKEN } from 'src/app/shared/constants/app-constants'
import { AppSnackBarService } from "src/app/shared/services/snackbar.service";
import { GlobalDataProviderService } from "src/app/shared/services/registration-data.service";
import { MembershipService } from "src/app/shared/services/membership.service";
import { MembershipDto } from "src/app/shared/models/order-details";

@Component({
    selector: 'app-member-roster-activate',
    templateUrl: './member-roster-activate.component.html',
    styleUrls: ['./member-roster-activate.component.scss']
})
export class MemberRosterActivateComponent implements OnInit {

    constructor(private injector: Injector,
        private modalService: ModalDataService,
        private snackbar: AppSnackBarService,
        private registrationService: GlobalDataProviderService,
        private membershipService: MembershipService) {

    }

    inputData: any;
    profileToUpdate: any;
    isActive: boolean | undefined;
    membersCount!: number;
    membership!: MembershipDto;
    forRenewal: boolean = false;

    ngOnInit(): void {
        this.inputData = this.injector.get(TOKEN);
        this.profileToUpdate = this.inputData.profile;
        this.isActive = this.inputData.isActive;
        this.forRenewal = this.inputData.forRenewal;
        this.membersCount = this.inputData.membersCount;
        this.membership = this.membershipService.activeMembership!;
    }

    activate() {
        if (this.profileToUpdate) {
            this.profileToUpdate.member.isActive = this.isActive;
            this.modalService.showLoader();
            this.profileToUpdate.member.isAddForRenewal = false;
            //update membercount for renewal flow #3497
            let activeMembersCount: number = this.membership.member.filter(m => m.isActive).length;
            let memberCount: any = !this.forRenewal ? undefined : this.membership.count < activeMembersCount ? this.membership.count + 1 : this.membership.count;
            if (this.forRenewal && !this.isActive) {
                // const index = this.membership.member.findIndex(m => m.id === this.profileToUpdate.id);
                // const member = this.membership.member[index];
                // if(this.isActive)   member.isAddForRenewal = true;
                // member.isActive = false;
                // this.membership.member[index] = member;
                // this.handleSuccess(this.membership);
                // return;
                this.profileToUpdate.member.isAddForRenewal = true;
                memberCount = this.membership.count > 10 && this.membership.count === (activeMembersCount + 1) ? this.membership.count - 1 : this.membership.count;
            }

            // for expired account restricting roster sync api in BE
            let restrictRosterSyncAPI = false;
            if(this.forRenewal && this.membership && new Date() > new Date(this.membership.endDate!)){
                restrictRosterSyncAPI = true;
            }

            this.membershipService.updateGracePeriod(this.membership.id!, this.profileToUpdate.member, memberCount, restrictRosterSyncAPI).subscribe({
                next: (value) => {
                    this.handleSuccess(value.data)
                },
                error: (error) => {
                    this.modalService.hideLoader();
                    if (!this.isActive) {
                        this.snackbar.showErrorMessage("Failed to deactivate the member", "OK");
                    } else {
                        this.snackbar.showErrorMessage("Failed to activate the member", "OK");
                    }
                    this.modalService.closeModal({ close: true });
                }
            })

        }
    }

    handleSuccess(membership: any) {
        this.modalService.hideLoader();
        if (!this.isActive) {
            this.snackbar.showSuccessMessage("Member deactivated successfully", "OK");
        } else {
            this.snackbar.showSuccessMessage("Member activated successfully", "OK");
        }
        if(membership)
            this.membershipService.setActiveMembership(membership);
        this.registrationService.setMemberRosterProfile(this.profileToUpdate);
        this.modalService.closeModal({ close: true });
    }

}